import React from "react";
import { graphql, StaticQuery } from "gatsby";

import MainLayout from "../components/MainLayout";
import Seo from "../components/Head";
import PrimaryButton from "../components/PrimaryButton";
import { Paragraph } from "../components/Utilities";

export default function Lost() {
  return (
    <StaticQuery
      query={graphql`
        {
          allWpPage(filter: { title: { eq: "404" } }) {
            edges {
              node {
                lost {
                  heroDescription
                  heroTitle
                  heroLink {
                    target
                    title
                    url
                  }
                  heroBackground {
                    altText
                    sourceUrl
                  }
                  metaDescription
                  metaTitle
                }
              }
            }
          }
        }
      `}
      render={(props) => (
        <MainLayout>
          <Seo
            meta_title={props.allWpPage.edges[0].node.lost.metaTitle}
            meta_title_backup={props.allWpPage.edges[0].node.lost.heroTitle}
            meta_description={
              props.allWpPage.edges[0].node.lost.metaDescription
            }
          />
          <section
            id="hero"
            className="lg:pt-[65px] bg-cover bg-no-repeat bg-center h-[600px] md:h-[800px] lg:h-[900px]"
            style={{
              backgroundImage: `url(${props.allWpPage.edges[0].node.lost.heroBackground.sourceUrl})`,
            }}
          >
            <div className="max-w-screen-xl mx-auto pt-28 md:pt-40 px-8 xl:px-0">
              <h1 className="gin-regular text-smoke text-[80px] sm:text-[125px] md:text-[200px]">
                {props.allWpPage.edges[0].node.lost.heroTitle}
              </h1>
              <Paragraph
                content={props.allWpPage.edges[0].node.lost.heroDescription}
                classes={"text-smoke mb-5 lg:mb-8"}
              />
              <PrimaryButton
                text={props.allWpPage.edges[0].node.lost.heroLink.title}
                textDefaultX={77}
                textHoverX={75}
                linkType={
                  props.allWpPage.edges[0].node.lost.heroLink.url.startsWith(
                    "http"
                  )
                    ? "external"
                    : "internal"
                }
                linkHref={props.allWpPage.edges[0].node.lost.heroLink.url}
              />
            </div>
          </section>
        </MainLayout>
      )}
    />
  );
}
